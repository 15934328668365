darwin_ent_centre_web.controller('eventBookNowController',
['bslSettings', '$scope', '$window', '$resource', '$stateParams', '$sce', '$location', '$cookies', 'eventDataService', '$timeout', function (bslSettings, $scope, $window, $resource, $stateParams, $sce, $location, $cookies, eventDataService, $timeout) {
    var resource = $resource(bslSettings.bslApiUrl + 'event/:id/');

    if ($stateParams.is_offsite)
        resource = $resource(bslSettings.bslApiUrlOffsite + 'event/:id/');

    // workaround for SRO limitation wrt cookies in iframe
    var userAgent = window.navigator.userAgent.toLowerCase();
    if ($stateParams.safariCallback){
        // TODO: later angular breaks this
        $cookies["newSafariSession"] = "1";
        history.back();
    }
    else if ($cookies["newSafariSession"]!="1" && userAgent.indexOf('safari') != -1 && userAgent.indexOf('chrome') == -1) {
        window.location.href="http://purchase.yourcentre.com.au/custom/Safari.aspx?url="+encodeURIComponent($location.absUrl()+"?safariCallback=true");
    }

    eventDataService.init().then(function() {
        $scope.event = eventDataService.eventsById[$stateParams.id];
        $scope.url  = $sce.trustAsResourceUrl($scope.event.external_url);

        $timeout(function(){
            $esro._private.initialize();
        }, 1);
    });

    $window.scrollTo(0, 0);

}]);
