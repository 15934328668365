darwin_ent_centre_web.controller('eventDetailController1',
[function () {
    }]);

darwin_ent_centre_web.controller('eventDetailController',
['id', 'bslSettings', '$scope', '$http', '$resource', '$location', '$sce', 'eventDataService', '$q', 
    function (id, bslSettings, $scope, $http, $resource, $location, $sce, eventDataService, $q) {
    
    $scope.hide_preview_button = true;
    $scope.id = id;

    angular.element(document).ready(function () {
        
        //Delay the fade in of the images within a modal to hide the empty box that shows first.
        $("#preload").load(function(evt){
            $(this).fadeIn(300);
      });
    
    });
    
    // :tag causes the param to be appended to the url
    // @tag causes the value to be taken from the record on save
    var resource = $resource(bslSettings.bslApiUrl + 'event/:id/');
    if (id.is_offsite === "true")
        resource = $resource(bslSettings.bslApiUrlOffsite + 'event/:id/');

    //console.log("passed in id:" + id);
    // clone event as well be modifying it
    eventDataService.init().then(function() {
        $scope.event = JSON.parse(JSON.stringify(eventDataService.eventsById[id.id]));
        $scope.event.images.main = $scope.event.images.main;

    
        if ('times' in $scope.event)
        {
            for (var idx in $scope.event.times)
            {
                if ('eventTime' in $scope.event.times[idx])
                    $scope.event.times[idx].time=$scope.event.times[idx].eventTime;
            }
        }

        if (typeof $scope.event.description === "string")
            $scope.event.description = $sce.trustAsHtml($scope.event.description);

        if (typeof $scope.event.long_name === "string")
            $scope.event.long_name = $sce.trustAsHtml($scope.event.long_name);

        if (typeof $scope.event.additionalInfo === "string")
            $scope.event.additionalInfo = $sce.trustAsHtml($scope.event.additionalInfo);

        if ($scope.event.ticket_info)
            $scope.event.ticket_info = JSON.parse($scope.event.ticket_info);

        $scope.event.ticketPricing = [];
        for (var key in $scope.event.ticket_info) {
          if ($scope.event.ticket_info.hasOwnProperty(key)) {
            if (key === 'Service Fee')
                continue;

            var visualOrder = 0;
            if (key === 'Full Price')
                visualOrder = 1;
            else if (key.match(/Member/g))
                visualOrder = 2;
            else if (key.match(/Concession/g))
                visualOrder = 3;
            else if (key.match(/Under|Child/g))
                visualOrder = 4;
            else
                visualOrder = 5;
            
            ticketClass = key

            // check for explicit visual order
            re = /^\d /
            var matches = re.exec(key);
            if (matches) {
                visualOrder =  parseInt(matches[0].trim());
                ticketClass = key.replace(matches[0],"");
            }

            // if the word free appears in the ticketClass
            // we don't include the $0.00
            $scope.event.ticketPricing.push({"isFree":key.match(/free/ig), "class":ticketClass, "price":$scope.event.ticket_info[key], 'visualOrder':visualOrder});
          }
        }

        $scope.hide_preview_button = (!('youtube_url' in $scope.event) || ($scope.event.youtube_url === "" || $scope.event.youtube_url === null));
        if ($scope.event.ticket_info)
            $scope.hide_ticket_service_fee_text = ("Free Event" in $scope.event.ticket_info);
        else
            $scope.hide_ticket_service_fee_text = true;

        $scope.service_fee = "$4.95"
        if ("Service Fee" in $scope.event.ticket_info) {
            $scope.service_fee = $scope.event.ticket_info["Service Fee"];
        }

        $scope.event.youtube_url = $sce.trustAsResourceUrl($scope.event.youtube_url);
        var pageId = $location.path() + "/" + $scope.event.tag;
        ga('send', 'pageview', pageId);    


        // get detail for existing record, get the detail
        /*resource.get({id: id.id}).$promise
            .then(function(result) {
                $scope.event = result;
                $scope.event.images.main = $scope.event.images.main;
                $scope.event.description = $sce.trustAsHtml($scope.event.description);
                $scope.event.long_name = $sce.trustAsHtml($scope.event.long_name);
                $scope.event.ticket_info = JSON.parse($scope.event.ticket_info);
                $scope.event.ticketPricing = [];
                for (var key in $scope.event.ticket_info) {
                  if ($scope.event.ticket_info.hasOwnProperty(key)) {
                    $scope.event.ticketPricing.push({"class":key, "price":$scope.event.ticket_info[key]})
                  }
                }
                $scope.hide_preview_button = (result.youtube_url === "" || result.youtube_url === null);
                $scope.hide_ticket_service_fee_text = ("Free Event" in $scope.event.ticket_info);
                $scope.event.youtube_url = $sce.trustAsResourceUrl($scope.event.youtube_url);
                var pageId = $location.path() + "/" + $scope.event.tag;
                ga('send', 'pageview', pageId);
            }, function (response) {
                //console.error("Failed to get event data.")
            });*/

        $scope.format_datetime = function(datetime) {

            // If this is a UTC date, convert to Darwin
            // local time. Otherwise, assume it already is.
            if (datetime.slice(-1) == "Z") {
                var utc = moment(datetime);
                datetime = utc.tz('Australia/Darwin').format()
            }

            dt = naiveDate(datetime);
            return dt.getDayName() + ' ' + dt.getDate() + ' ' + dt.getMonthShortName() + ' ' + dt.get12HourTime();
        }

        // $scope.ok = function () {
        //     $modalInstance.close({'book_now':false, 'is_offsite':id.is_offsite});
        // };

        // $scope.cancel = function () {
        //     $modalInstance.dismiss('cancel');
        // };

        // $scope.openTicketPurchaseWindow = function() {
        //     $modalInstance.close({'book_now':true, 'is_offsite':id.is_offsite});
        // };
        
        if (id.is_offsite === "true") {
            $q.when(eventDataService.offsite_image_src_from_id(id.id))
                .then(function(result) {
                    $scope.image_src = result;
                })
        } else {
            $q.when(eventDataService.image_src_from_id(id.id))
                .then(function(result) {
                    $scope.image_src = result;
                })
        }
    });

}]);
