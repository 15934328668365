darwin_ent_centre_web.controller('eventPreviewController',
['bslSettings', '$scope', '$http', '$resource', '$modalInstance', 'id', '$sce', 'eventDataService', function (bslSettings, $scope, $http, $resource, $modalInstance, id, $sce, eventDataService) {
    // :tag causes the param to be appended to the url
    // @tag causes the value to be taken from the record on save
    var resource = $resource(bslSettings.bslApiUrl + 'event/:id/');
    if (id.is_offsite)
        resource = $resource(bslSettings.bslApiUrlOffsite + 'event/:id/');


    eventDataService.init().then(function() {
        $scope.event = eventDataService.eventsById[id.id];
        $scope.youtube_url = $sce.trustAsResourceUrl($scope.event.youtube_url);
    });

    $scope.format_datetime = function(datetime) {
        dt = new Date(datetime);
        return dt.getDayName() + ' ' + dt.getDate() + ' ' + dt.getMonthShortName() + ' ' + dt.get12HourTime();
    }

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.openTicketPurchaseWindow = function() {
        $modalInstance.close(true);
    };

}]);
