darwin_ent_centre_web.config(function($stateProvider, $urlRouterProvider) {
    
    $urlRouterProvider.otherwise('events');

    $stateProvider
        .state('test', {
            url: '/test/:bsl_url',
            onEnter: ['bslSettings', '$state', '$stateParams', '$timeout', function (bslSettings, $state, $stateParams, $timeout) {
                bslSettings.rebase($stateParams.bsl_url);
                $state.go("events");
            }],
        });

    $stateProvider
        .state('events', {
            url: '/events',
            templateUrl: 'content/views/events-table.html',
            controller: 'eventsTableController',
        });

   $stateProvider
        .state('events.modal', {
            url: '',
            abstract: true,
            onEnter: ['$modal', '$state', function ($modal, $state) {
              var nextState = {state: "events"};
              $modal.open({
                    template: '<div ui-view="modal"></div>',
                    size: "lg",
                    resolve: {
                        nextState: function() {return nextState;}
                    },
                })
                .result
                .then(function(result, args){
                    if (result)
                        $state.go(result.state, result.args);
                    else
                        $state.go("events");   
                }, function () {
                    $state.go("events");                       
                });
            }],
        });

   $stateProvider
        .state('events.detail', {
            parent: 'events.modal',
            url: '/:id/:is_offsite',
            views: {
                'modal@' : {
                    templateUrl: 'content/views/event-detail.html',
                    controller: 'eventDetailController',
                    resolve: {
                        id: ['$stateParams', function ($stateParams) {
                          return {id: $stateParams.id, is_offsite: $stateParams.is_offsite};
                        }],
                    },              
                }
            }
        });

   $stateProvider
        .state('popOver', {
            parent: 'events.modal',
            views: {
                'modal@' : {
                    templateUrl: 'content/views/pop-over-ad.html',
                    resolve: {
                        id: ['$stateParams', function ($stateParams) {
                          return {id: $stateParams.id, is_offsite: $stateParams.is_offsite};
                        }],
                    },              
                }
            }
        });

    $stateProvider
        .state('event_book_now', { 
            url: '/event_book_now/:id/?safariCallback',
            templateUrl: 'content/views/event-book-now.html',
            controller: 'eventBookNowController',
        });

    $stateProvider
        .state('memberships', { 
            url: '/memberships/',
            templateUrl: 'content/views/memberships.html',
            controller: function($scope, $sce, $rootScope) {
                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/membership");
                valueRef.on('value', function(result) {
                    var snap = result.val();
                    $scope.membership = result.val();
                    $scope.membership.text = $sce.trustAsHtml($scope.membership.text);
                    $scope.$digest();
                });
            }
        });

    /*$stateProvider
        .state('memberships_renew', { 
            url: '/memberships_renew/',
            template: '<section class="member"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container"><h1>Renew your Membership</h1><iframe id="myIframe" ng-src="https://purchase.yourcentre.com.au/en-AU/memberships/the centre club" > </iframe></div></div></div>',
        });*/

    $stateProvider
        .state('memberships_renew', { 
            url: '/memberships_renew/',
            templateUrl: 'content/views/memberships_renew.html',
            controller: function($scope, $sce, $rootScope) {
                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/membership_renew");
                valueRef.on('value', function(result) {
                    var snap = result.val();
                    $scope.membership_renew = result.val();
                    $scope.membership_renew.text = $sce.trustAsHtml($scope.membership_renew.text);
                    $scope.$digest();
                });
            }
        });

    $stateProvider
        .state('become_member', { 
            url: '/become_member/',
            template: '<section class="member"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container"><h1>Become a member</h1><iframe id="myIframe" ng-src="https://purchase.yourcentre.com.au/en-AU/memberships/the centre club" > </iframe></div></div></div>',
        });


    $stateProvider
        .state('book_placholder', { 
            url: '/book_placholder/',
            template: '<section class="member"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container"><br><br><br><h1>Looking for tickets?<br><br>You’ve caught us upgrading our ticketing system to improve your purchasing experience.</h1><p>We don’t want you to leave empty-handed, so snap a shot of the code below to redeem one free beverage at our bar on your next visit. We’ll be back up and running soon!<br><h1>FREEDECBEV</h1>* Valid for use before 19 May 2024. Limited to one drink per patron.</p></div></div></div>',
        });

    $stateProvider
        .state('login_member', { 
            url: '/login_member/',
            template: '<section class="member"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container"><h1>Account login</h1><iframe id="myIframe" ng-src="https://mpv.tickets.com/?agency=DENM_MYTIXX&orgid=55074#/auth/login" > </iframe></div></div></div>',
        });

    $stateProvider
        .state('gift_card', { 
            url: '/gift_voucher/',
            template: '<section class="member"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container"><!--<h1>Gift Voucher</h1>--><h2>Gift Vouchers are temporarily unavailable online.</h2><p>Please check back later or contact boxoffice@yourcentre.com.au or call 08 8980 3333.</p><!--<iframe ng-src="https://purchase.yourcentre.com.au/en-AU/giftvouchers/gift%20voucher" > </iframe>--></div></div></div>',        });

    $stateProvider
        .state('logout_member', { 
            url: '/logout_member/',
            controller: function($scope, $state){
                // just got back to events, sro sucks
                $state.go('events');
            }
        });

    $stateProvider
        .state('subscribe_enews', { 
            url: '/subscribe_enews/',
            //template: '<section class="subscribe-enews"><div class="container"><div class="row"><div class="col-md-12 col-lg-10 col-lg-offset-1 iframe-container" style="background:white; padding:0px 50px 50px 50px"><h1>Subscribe to your enews</h1><iframe ng-src="https://purchase.yourcentre.com.au/crmcreateaccount.aspx" > </iframe></div></div></div>',
            templateUrl: 'content/views/enews.html',
        });

    $stateProvider
        .state('hire_corporate', { 
            url: '/about_the_centre/hire_corporate/',
            templateUrl: 'content/views/hire-corporate.html',
        });

    $stateProvider
        .state('hire_live_events', { 
            url: '/about_the_centre/live_events/',
            templateUrl: 'content/views/hire-live-events.html',
        });
        
    /*$stateProvider
        .state('other_services', { 
            url: '/about_the_centre/other_services/',
            templateUrl: 'content/views/other_services.html',
        });*/
        
    $stateProvider
        .state('event_management', { 
            url: '/about_the_centre/other_services/event_management/',
            templateUrl: 'content/views/event_management.html',
        });
        
    $stateProvider
        .state('marketing', { 
            url: '/about_the_centre/other_services/marketing/',
            templateUrl: 'content/views/marketing.html',
        });
        
    $stateProvider
        .state('production', { 
            url: '/about_the_centre/other_services/production/',
            templateUrl: 'content/views/production.html',
        });
        
     $stateProvider
        .state('external_ticketing', { 
            url: '/about_the_centre/other_services/external_ticketing/',
            templateUrl: 'content/views/external_ticketing.html',
        });

      $stateProvider
        .state('board_profiles', { 
            url: '/about_the_centre/board_profiles/',
            templateUrl: 'content/views/board-profiles.html',
            controller: function($scope, $sce, $rootScope) {
                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/boardProfiles");
                valueRef.on('value', function(result) {
                    $scope.boardProfiles = result.val();
                    for (var key in $scope.boardProfiles) {
                        $scope.boardProfiles[key]["bio"] = $sce.trustAsHtml($scope.boardProfiles[key]["bio"]);
                    }
                    $scope.$digest();
                });
            }
        });

      $stateProvider
        .state('other_services', { 
            url: '/about_the_centre/other_services/',
            templateUrl: 'content/views/other_services_2.html',
            controller: function($scope, $sce, $rootScope) {
                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/otherServices");
                valueRef.on('value', function(result) {
                    let otherServices = result.val();
                    $scope.otherServices = [];

                    for (var key in otherServices) {
                        otherServices[key]["id"] = key;
                        otherServices[key]["content"] = $sce.trustAsHtml(otherServices[key]["content"]);
                        otherServices[key]["visualOrder"] = parseInt(otherServices[key]["visualOrder"]);
                        $scope.otherServices.push(otherServices[key]);
                    }
                    if(!$scope.$$phase)
                        $scope.$digest();
                });
            }
        });

      $stateProvider
        .state('other_service', { 
            url: '/about_the_centre/other_services/:id',
            templateUrl: 'content/views/other_service.html',
            controller: function($scope, $sce, $rootScope, $stateParams) {
                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/otherServices/"+$stateParams.id);
                valueRef.on('value', function(result) {
                    $scope.otherService = result.val();
                    $scope.otherService["content"] = $sce.trustAsHtml($scope.otherService["content"]);
                });
            }
        });

      $stateProvider
        .state('careers', { 
            url: '/about_the_centre/careers/',
            templateUrl: 'content/views/careers.html',
        });
      
      $stateProvider
        .state('faq', { 
            url: '/about_the_centre/faq/',
            templateUrl: 'content/views/faq.html'
        });

      $stateProvider
        .state('contact_us', { 
            url: '/contact_us/',
            templateUrl: 'content/views/contact_us.html',
        });
        
        $stateProvider
        .state('privacy', { 
            url: '/privacy/',
            templateUrl: 'content/views/privacy.html',
        });

        $stateProvider
        .state('entry_conditions', { 
            url: '/entry_conditions/',
            templateUrl: 'content/views/entry_conditions.html',
        });

        $stateProvider
        .state('ticket_terms', { 
            url: '/ticket_terms/',
            templateUrl: 'content/views/ticket_terms.html',
        });
        
        $stateProvider
        .state('lpa_code_of_practice', { 
            url: '/lpa_code_of_practice/',
            templateUrl: 'content/views/lpa_code_of_practice.html',
        });

        $stateProvider
        .state('privacy_policy', { 
            url: '/privacy_policy/',
            templateUrl: 'content/views/privacy_policy.html',
        });

        $stateProvider
        .state('complaints_policy', { 
            url: '/complaints_policy/',
            templateUrl: 'content/views/complaints_policy.html',
        });

        $stateProvider
        .state('news', {
            url: '/news',
            templateUrl: 'content/views/blog.html',
            controller: function($scope, $sce, $rootScope, $timeout, Socialshare, $location) {
                /*$scope.blogPosts = {
                    "1" : {
                        title:"Welcome to the Your Centre Blog!",
                        image:"images/live_performance.jpg",
                        date:"",
                        intro:"<p>adsfdasfsajhdb skd dks dlsfl djsfglj. sdlfglkjdfg. asdflglkdjf dsjg fladjfglakjdf g dsf adsfdsf df asdf asdfsadf. dsf adf. dsaf adf dfasfasdf adf adsf aadf adf a</p>",
                        content:"<p>adsfdasfsajhdb skd dks dlsfl djsfglj. sdlfglkjdfg. asdflglkdjf dsjg fladjfglakjdf g dsf adsfdsf df asdf asdfsadf. dsf adf. dsaf adf dfasfasdf adf adsf aadf adf a</p><p>adsfdasfsajhdb skd dks dlsfl djsfglj. sdlfglkjdfg. asdflglkdjf dsjg fladjfglakjdf g dsf adsfdsf df asdf asdfsadf. dsf adf. dsaf adf dfasfasdf adf adsf aadf adf a.</p>",
                        isCollapsed:true,
                        tags:"comedy drama"
                    },
                    "2" : {
                        title:"Blah Post",
                        image:"",
                        date:"",
                        intro:"",
                        content:"",
                        isCollapsed:true,
                        tags:"blah"
                    },
                };*/

                $scope.tagNames = ['comedy','theatre','dance'];
                $scope.tags = {};
                for (var idx in $scope.tagNames) {
                    $scope.tags[$scope.tagNames[idx]] = {isSelected:true,borderWidth:5};
                }

                $scope.isVisible = function(post) {
                    if ('tags' in post) {
                        var tags = post.tags.split(" ");
                        for (var idx in tags) {
                            if ($scope.tagNames.indexOf(tags[idx].toLowerCase()) > -1)
                                if ($scope.tags[tags[idx]].isSelected)
                                    return true;
                        }
                    }
                    return false;
                }

                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/blogPosts");
                valueRef.on('value', function(result) {
                    $scope.blogPosts = [];
                    blogPosts = result.val();
                    
                    for (var idx in blogPosts) {
                        blogPosts[idx].content = $sce.trustAsHtml(blogPosts[idx].content);
                        blogPosts[idx].intro = $sce.trustAsHtml(blogPosts[idx].intro);
                        blogPosts[idx].youtube_url = $sce.trustAsResourceUrl(blogPosts[idx].youtube_url);
                        blogPosts[idx].isCollapsed = true;
                        blogPosts[idx].date = new Date(blogPosts[idx]._created);
                        blogPosts[idx].isVisible = $scope.isVisible(blogPosts[idx]);
                        $scope.blogPosts.push(blogPosts[idx]);
                    }
                    
                    $scope.blogPosts.sort(function(a, b) {
                        return a._created < b._created;
                    });

                    $timeout( function(){
                        $scope.$digest();
                    }, 0 );
                });


                $scope.expandOrCollapse = function(post) {
                    console.log("sdsd")
                    post.isCollapsed = !post.isCollapsed;
                }

                $scope.toggleSelectedTag = function(tag) {
                    $scope.tags[tag].isSelected = !$scope.tags[tag].isSelected;
                    if ($scope.tags[tag].isSelected)
                        $scope.tags[tag].borderWidth = 5;
                    else
                        $scope.tags[tag].borderWidth = 0;

                    for (var idx in $scope.blogPosts) {
                        $scope.blogPosts[idx].isVisible = $scope.isVisible($scope.blogPosts[idx]);
                    }
                    $timeout( function(){
                        $scope.$digest();
                    }, 0 );
                }

                console.log($location.absUrl());

                $scope.onShare = function(post, provider) {
                     Socialshare.share({
                          'provider': provider,
                          'attrs': {
                            'socialshareUrl': $location.absUrl(),
                            'socialshareText': post.title
                          }
                        });
                }
            }
        });

        $stateProvider
        .state('darwin_festival', {
            url: '/darwin_festival',
            templateUrl: 'content/views/darwin_festival.html',
            controller: 'darwinFestivalController',
        });

        $stateProvider
        .state('darwin_festival.modal', {
            url: '',
            abstract: true,
            onEnter: ['$modal', '$state', function ($modal, $state) {
              var nextState = {state: "darwin_festival"};
              $modal.open({
                    template: '<div ui-view="modal"></div>',
                    size: "lg",
                    resolve: {
                        nextState: function() {return nextState;}
                    },
                })
                .result
                .then(function(result, args){
                    if (result)
                        $state.go(result.state, result.args);
                    else
                        $state.go("darwin_festival");   
                }, function () {
                    $state.go("darwin_festival");                       
                });
            }],
        });

        $stateProvider
        .state('darwin_festival.detail', {
            parent: 'darwin_festival.modal',
            url: '/:id',
            views: {
                'modal@' : {
                    templateUrl: 'content/views/event-detail.html',
                    controller: 'eventDetailController',
                    resolve: {
                        id: ['$stateParams', function ($stateParams) {
                          return {id: $stateParams.id, is_offsite: $stateParams.is_offsite, is_darwin_festival: $stateParams.is_darwin_festival};
                        }],
                    },              
                }
            }
        });

        $stateProvider
        .state('page', {
            url: '/page/:id',
            templateUrl: 'content/views/page.html',
            controller: function($scope, $sce, $rootScope, $stateParams, $timeout, $window) {

                var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/page/");
                valueRef.on('value', function(result) {
                    var pages = result.val();
                    $rootScope.pages = {};
                    for (var idx in pages) {
                      var id = idx;
                      if ('tag' in pages[idx]) {
                        id = pages[idx]['tag'];
                      }
                      pages[idx]['content'] = $sce.trustAsHtml(pages[idx]['content']);
                      $rootScope.pages[id] = pages[idx];
                    }

                    console.log($stateParams);
                    if ($rootScope.pages && $stateParams.id in $rootScope.pages)
                        $scope.page = $rootScope.pages[$stateParams.id];

                    $timeout( function(){
                        $rootScope.$digest();
                        $window.scrollTo(0, 0);
                    }, 0 );
                });


            }
        });

});
