darwin_ent_centre_web.controller('eventsTableController',
['bslSettings', '$scope', '$state', '$http', '$resource', '$modal', '$q', 'eventDataService', '$sce', '$location', '$rootScope', function (bslSettings, $scope, $state, $http, $resource, $modal, $q, eventDataService, $sce, $location, $rootScope) {

    /*if (!window.sessionStorage.getItem("popOver_shown") && ($location.path() === "/events"))
    {
      window.sessionStorage.setItem("popOver_shown", true);
      $state.go('popOver');
    }*/
    
    $scope.secondarySliderImageBaseUrl = "https://www.ntix.com.au/index.html#!/events/";
    if (_isNtix)
          $scope.secondarySliderImageBaseUrl = "https://www.yourcentre.com.au/index.html#!/events/"; 

    angular.element(document).ready(function () {
        //$('.icon-search--toggle').click(function () {
        //
        //    $('.search__inside').slideToggle("fast")
        //    $(this).toggleClass('closer');
        //
        //});

        if ($(window).width() < 767) {
            $(".show__inside").removeAttr("data-toggle");
        } else {
            $(".show__inside").attr("data-toggle", "modal");
        }
        eventDataService.load_main_images();
    });

    $scope.mediaUrl = bslSettings.bslMediaUrl;
     

    // get sponsor list over V2 API
    /*$http.get(bslSettings.bslApiV2Url + 'dec/sponsors/')
      .then(function(response) {
            ga('send', 'event', 'sponsors get success: dec/sponsors/');

          $scope.sponsors = response.data.values;
        },
        function(error) {
            ga('send', 'event', 'sponsors get FAIL: dec/sponsors/');

        })*/


    $scope.mainSliderSettings = {
        autoplay: false,
        speed:1200,
        dots: true,
        arrows: true,
        infinite: false
    };

    $scope.secondarySliderSettings = {
        autoplay: false,
        speed:1200,
        dots: true,
        arrows: true,
        infinite: false
    };
    
    $scope.feature_panel = {};
    
    // used to only initialise the slider once data is loaded
    eventDataService.init().then(function() {
      $rootScope.initialised = true;
      $scope.loaded = eventDataService.events['loaded'];
      $scope.events = eventDataService.events['events'];
      $scope.ntix_loaded = eventDataService.events['ntix_loaded'];
      $scope.ntix_events = eventDataService.events['ntix_events'];
      $scope.featured = eventDataService.events['featured'];
      $scope.ntix_featured = eventDataService.events['ntix_featured'];

      $scope.mainSliderSettings.autoplay = true;

    });

    // handles ng-click event
    $scope.eventDetail = function (id, is_offsite) {
      $state.go("events.detail", { id: id, is_offsite: is_offsite });
    }

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.openDetailModal = function (id, is_offsite) {

//      $state.go("events.detail", {id: id, is_offsite: is_offsite});

      // var modalInstance = $modal.open({
      //       templateUrl: 'content/views/event-detail.html',
      //       controller: 'eventDetailController',
      //       size: "lg",
      //       resolve: {
      //         id: function () {
      //           return {id:id, is_offsite:is_offsite};
      //         }
      //       }
      //     });

      //   modalInstance.result.then(function(modal_data){
      //     //console.log('Modal dismissed at: ' + new Date());
      //     if (modal_data.book_now)
      //       $state.go("event_book_now", {'id':id, 'is_offsite':modal_data.is_offsite});
      //   }, function () {
      //     //console.log('Modal dismissed at: ' + new Date());
      //   });
    }

    $scope.openPreviewModal = function (id, is_offsite) {

    var modalInstance = $modal.open({
          templateUrl: 'content/views/event-preview.html',
          controller: 'eventPreviewController',
          size: "lg",
          resolve: {
            id: function () {
              return {id:id, is_offsite:is_offsite};
            }
          }
        });

        modalInstance.result.then(function () {
          //console.log('Modal dismissed at: ' + new Date());
        }, function () {
          //console.log('Modal dismissed at: ' + new Date());
        });
    }
    
          $scope.show_panel = false;
    $scope.showPanel = function () {
      $scope.show_panel = true;
    }
    
    
    
    
    

}]);



