darwin_ent_centre_web.factory('eventDataService', ['$resource', 'bslSettings', '$q', '$sce', '$rootScope', function ($resource, bslSettings, $q, $sce, $rootScope) {

    var resource = $resource(bslSettings.bslApiUrl + 'event/', {limit: 0});

    var images_deferred = $q.defer();
    var main_images = images_deferred.promise;

    var offsite_images_deferred = $q.defer();
    var main_offsite_images = offsite_images_deferred.promise;

    var darwin_festival_images_deferred = $q.defer();
    var main_darwin_festival_images = darwin_festival_images_deferred.promise;

     var _events = {'events':[],'ntix_events':[]};
     var _eventsById = {'events':{},'ntix_events':{}};
     var _ntixEventsById = {};
     var _initialised = false;
     var _initialising = false;
     var deferred = $q.defer();

     var update_resource = function (url, events, events_var, featured, loaded, firebaseEvent) {
            ga('send', 'event', 'resource get success:'+ url + 'event/');

            _events[loaded] = true;

            _events[events_var] = _events[events_var].concat(events);
            for (var idx = _events[events_var].length -1; idx >=0; idx--)
            {
              var times = [];
              var event = _events[events_var][idx];

              if (!firebaseEvent)
                if ('images' in event) {
                  event.images.event_front_page = bslSettings.bslMediaUrl + event.images.event_front_page;
                  event.images.main = bslSettings.bslMediaUrl + event.images.main;

                }

              // event.tag is undefined for deleted events
              if (event.tag === undefined)
              {
                  _events[events_var].splice(idx, 1);
                  continue;
              }
              if (event.tag.slice(0,1) === "-")
              {
                  _events[events_var].splice(idx, 1);
                  continue;
              }
              
              for (idx2 in event.times)
              {
                if ('eventTime' in event.times[idx2])
                  times.push(event.times[idx2].eventTime);
                else
                  times.push(event.times[idx2].time);
              }
              
              if (times.length === 0)
                continue;
              else if (times.length === 1)
              {

                var datetime = times[0];

                if (datetime.slice(-1) == "Z") {
                    var utc = moment(datetime);
                    datetime = utc.tz('Australia/Darwin').format()
                }

                var earliest = naiveDate(datetime);

                _events[events_var][idx].date_string = earliest.getDate() + ' ' + earliest.getMonthName();
                _events[events_var][idx].sorted_times = times;
              }
              else
              {
                times.sort();
                

                var earliest_datetime = times[0];
                var latest_datetime = times[times.length-1];

                if (earliest_datetime.slice(-1) == "Z") {
                    var utc = moment(earliest_datetime);
                    earliest_datetime = utc.tz('Australia/Darwin').format()
                }

                if (latest_datetime.slice(-1) == "Z") {
                    var utc = moment(latest_datetime);
                    latest_datetime = utc.tz('Australia/Darwin').format()
                }


                var earliest = naiveDate(earliest_datetime);
                var latest = naiveDate(latest_datetime);

                var earliest_string = earliest.getDate() + ' ' + earliest.getMonthName() + ' — '
                var latest_string = latest.getDate() + ' ' + latest.getMonthName();
                if (earliest.getDate() === latest.getDate())
                  earliest_string = "";
                _events[events_var][idx].date_string = earliest_string + latest_string;
                _events[events_var][idx].sorted_times = times;
              }
              _events[events_var][idx].hide_preview_button = !event.youtube_url;

              if (typeof _events[events_var][idx].short_name === "string")
                _events[events_var][idx].short_name = $sce.trustAsHtml(_events[events_var][idx].short_name);

              _eventsById[events_var][_events[events_var][idx].id] = _events[events_var][idx];
            }

            // filter out the featured events
            var re = /^F\d/
            _events[featured] = _events[events_var].filter(function(item) {
                  return item.hidden == false && re.test(item.tag.toString());
            });
    };
    
    update_error_handler = function(url) {
        _initialising = false;
        return function (response) {
            //console.error("Failed to get event data.")
            ga('send', 'event', 'resource get FAIL:'+ url + 'event/');
        }
    }

    function init() {

        if (_initialised)
        {
          deferred.resolve();
          return deferred.promise;
        }
        else if (_initialising) {
          return deferred.promise;
        }
        _initialising = true;

        // initial request for the list
        var projectsRef = firebase.database().ref($rootScope.customisations.primaryShowDataFb);
        var secondaryShowsRef = firebase.database().ref($rootScope.customisations.secondaryShowDataFb);

              projectsRef.once('value', function(result) {
                  var posts = result.val();
                  var events = [];
                  for (var idx in posts) {
                    var event = {};
                    var post = posts[idx];
                    event.description = post.description;
                    event.external_url = post.externalLink;
                    event.booking_url_redirect = post.bookingUrlRedirect;
                    event.id = idx;
                    event.images = {'event_front_page':post.frontPageImage,'main':post.mainImage};
                    event.long_name = post.longName;
                    event.short_name = post.shortName;
                    event.tag = post.tag;
                    event.ticket_info = post.ticketInformation;
                    event.times = post.eventTimes;
                    event.uuid = idx;
                    event.venue = post.venue;
                    event.youtube_url = post.youtubeLink;
                    event.duration = post.duration;
                    event.additionalInfo = post.additionalInfo;
                    event.hidden = true;

                    var now = new Date();
                    var tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    var publishDate = tomorrow;
                    var removeDate = tomorrow;

                    if ('publishDate' in post)
                      publishDate = new Date(post.publishDate);
                    
                    if ('removeDate' in post)
                      removeDate = new Date(post.removeDate);

                    if (now >= publishDate && now < removeDate)
                      event.hidden = false;

                    events.push(event);
                  }
                  //events = [];
                  update_resource(bslSettings.bslApiUrl, events, 'events', 'featured', 'loaded', true);

                  secondaryShowsRef.once('value', function(result) {
                      var posts = result.val();
                      var events = [];
                      for (var idx in posts) {
                        var event = {};
                        var post = posts[idx];
                        event.description = post.description;
                        event.external_url = post.externalLink;
                        event.booking_url_redirect = post.bookingUrlRedirect;
                        event.id = idx;
                        event.images = {'event_front_page':post.frontPageImage,'main':post.mainImage};
                        event.long_name = post.longName;
                        event.short_name = post.shortName;
                        event.tag = post.tag;
                        event.ticket_info = post.ticketInformation;
                        event.times = post.eventTimes;
                        event.uuid = idx;
                        event.venue = post.venue;
                        event.youtube_url = post.youtubeLink;
                        event.duration = post.duration;
                        event.additionalInfo = post.additionalInfo;
                        event.hidden = true;

                        var now = new Date();
                        var tomorrow = new Date();
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        var publishDate = tomorrow;
                        var removeDate = tomorrow;

                        if ('publishDate' in post)
                          publishDate = new Date(post.publishDate);
                        
                        if ('removeDate' in post)
                          removeDate = new Date(post.removeDate);

                        if (now >= publishDate && now < removeDate)
                          event.hidden = false;

                        events.push(event);
                      }
                      update_resource(bslSettings.bslApiSecondaryUrl, events, 'ntix_events', 'ntix_featured', 'ntix_loaded', true);
                      deferred.resolve();
                      _initialised = true;
                      _initialising = false;

                    });
              });
        
        return deferred.promise;

    }

    function load_main_images() { 
        var projectsRef = firebase.database().ref($rootScope.customisations.primaryShowDataFb);

        projectsRef.once('value', function(result) {

                var tmp = {};
                var posts = result.val();
                var events = [];
                for (var idx in posts) {
                  var event = {};
                  var post = posts[idx];
                  event.images = {'event_front_page':post.frontPageImage,'main':post.mainImage};
                  event.long_name = post.longName;
                  event.short_name = post.shortName;
                  event.tag = post.tag;
                  events.push(event);
                }

                for (var idx = events.length -1; idx >=0; idx--)
                {
                    anEvent = events[idx];
                    // event.tag is undefined for deleted events
                    if (anEvent.tag === undefined)
                    {
                        events.splice(idx, 1);
                        continue;
                    }
                    image = {}; 
                    image.src = bslSettings.bslMediaUrl + anEvent.images.main;
                    tmp[anEvent.id] = image;
                    //console.log("loaded imag: " + anEvent.images.main)
                }
                images_deferred.resolve(tmp);
        })
    }

    return {
        init: init,

        events: _events,

        eventsById: _eventsById['events'],

        ntixEventsById: _ntixEventsById['ntix_events'],

        load_main_images: load_main_images,

        image_src_from_id: function(id) {
            return $q.when(main_images)
                .then(function (result) {
                    if (id in result)
                        return result[id].src;
                    return _eventsById['events'][id].images.main;
                });
        },

        offsite_image_src_from_id: function(id) {
            return $q.when(main_offsite_images)
                .then(function (result) {
                    return result[id].src;
                });
        }
    }
}]);
