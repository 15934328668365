darwin_ent_centre_web.controller('darwinFestivalController',
['bslSettings', '$scope', '$state', '$http', '$resource', '$modal', '$q', 'eventDataService', '$sce', function (bslSettings, $scope, $state, $http, $resource, $modal, $q, eventDataService, $sce) {

    angular.element(document).ready(function () {
        //$('.icon-search--toggle').click(function () {
        //
        //    $('.search__inside').slideToggle("fast")
        //    $(this).toggleClass('closer');
        //
        //});

        if ($(window).width() < 767) {
            $(".show__inside").removeAttr("data-toggle");
        } else {
            $(".show__inside").attr("data-toggle", "modal");
        }
        eventDataService.load_main_images()
    });

    $scope.mediaUrl = bslSettings.bslMediaUrl;
     

    var resource = $resource(bslSettings.bslApiUrl + 'event/', {limit: 0});

    $scope.feature_panel = {};

    // initial request for the list
    resource.get().$promise
        .then(function(result) {
            ga('send', 'event', 'resource get success:'+ bslSettings.bslApiUrl + 'event/');

            $scope.events = result.objects;
            for (var idx = $scope.events.length -1; idx >=0; idx--)
            {
              var times = [];
              var event = $scope.events[idx];

              // event.tag is undefined for deleted events
              if (event.tag === undefined)
              {
                  $scope.events.splice(idx, 1);
                  continue;
              }
              if (event.tag === "feature_panel_left" || event.tag === "feature_panel_right")
              {
                $scope.feature_panel[event.tag] = event;
                $scope.feature_panel[event.tag].description = $sce.trustAsHtml(event.description);
                $scope.events.splice(idx, 1);
                continue;
              }             
              if (event.tag.slice(0,1) === "-")
              {
                  $scope.events.splice(idx, 1);
                  continue;
              }
              for (idx2 in event.times)
                times.push(event.times[idx2].time);

              if (times.length === 0)
                continue;
              else if (times.length === 1)
              {
                var earliest = naiveDate(times[0]);
                $scope.events[idx].date_string = earliest.getDayName() + ' ' 
                      + earliest.getDate() + ' ' + earliest.getMonthShortName()
              }
              else
              {
                times.sort();
                var earliest = naiveDate(times[0]);
                var latest = naiveDate(times[times.length-1]);
                var earliest_string = earliest.getDayName() + ' ' + earliest.getDate() + ' ' + earliest.getMonthShortName() + ' - '
                var latest_string = latest.getDayName() + ' ' + latest.getDate() + ' ' + latest.getMonthShortName();
                if (earliest.getDate() === latest.getDate())
                  earliest_string = "";
                $scope.events[idx].date_string = earliest_string + latest_string;
              }
              $scope.events[idx].hide_preview_button = (event.youtube_url === "" || event.youtube_url === null);

            }



          }, function (response) {
              //console.error("Failed to get event data.")
              ga('send', 'event', 'resource get FAIL:'+ bslSettings.bslApiUrl + 'event/');
          });

    var resource_offsite = $resource(bslSettings.bslApiUrlOffsite + 'event/', {limit: 0});

    
    resource_offsite.get().$promise
        .then(function(result) {
            $scope.offsite_events = result.objects;

            $scope.$watch('offsite_events', function(){
              $('.carousel').slick({
                dots: true,
                infinite: false,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                      infinite: false,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: false,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
              });
            });
            for (var idx = $scope.offsite_events.length -1; idx >=0; idx--)
            {
              var times = [];
              var event = $scope.offsite_events[idx];

              // event.tag is undefined for deleted events
              if (event.tag === undefined)
              {
                $scope.offsite_events.splice(idx, 1);
                continue;
              }
              if (event.tag.slice(0,1) === "-")
              {
                  $scope.offsite_events.splice(idx, 1);
                  continue;
              }
              for (idx2 in event.times)
                times.push(event.times[idx2].time);

              if (times.length === 0)
                continue;
              else if (times.length === 1)
              {
                var earliest = naiveDate(times[0]);
                $scope.offsite_events[idx].date_string = earliest.getDayName() + ' ' 
                      + earliest.getDate() + ' ' + earliest.getMonthShortName()
              }
              else
              {
                times.sort();
                var earliest = naiveDate(times[0]);
                var latest = naiveDate(times[times.length-1]);
                var earliest_string = earliest.getDayName() + ' ' + earliest.getDate() + ' ' + earliest.getMonthShortName() + ' - '
                var latest_string = latest.getDayName() + ' ' + latest.getDate() + ' ' + latest.getMonthShortName();
                $scope.offsite_events[idx].date_string = earliest_string + latest_string;
              }
              $scope.offsite_events[idx].hide_preview_button = (event.youtube_url === "" || event.youtube_url === null);
            }
          }, function (response) {
              //console.error("Failed to get event data.")
          });

    // handles ng-click event
    $scope.eventDetail = function (id, is_offsite) {
      $state.go("events.detail", { id: id, is_offsite: is_offsite });
    }

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.openDetailModal = function (id, is_offsite) {

//      $state.go("events.detail", {id: id, is_offsite: is_offsite});

      // var modalInstance = $modal.open({
      //       templateUrl: 'content/views/event-detail.html',
      //       controller: 'eventDetailController',
      //       size: "lg",
      //       resolve: {
      //         id: function () {
      //           return {id:id, is_offsite:is_offsite};
      //         }
      //       }
      //     });

      //   modalInstance.result.then(function(modal_data){
      //     //console.log('Modal dismissed at: ' + new Date());
      //     if (modal_data.book_now)
      //       $state.go("event_book_now", {'id':id, 'is_offsite':modal_data.is_offsite});
      //   }, function () {
      //     //console.log('Modal dismissed at: ' + new Date());
      //   });
    }

    $scope.openPreviewModal = function (id, is_offsite) {

    var modalInstance = $modal.open({
          templateUrl: 'content/views/event-preview.html',
          controller: 'eventPreviewController',
          size: "lg",
          resolve: {
            id: function () {
              return {id:id, is_offsite:is_offsite};
            }
          }
        });

        modalInstance.result.then(function () {
          //console.log('Modal dismissed at: ' + new Date());
        }, function () {
          //console.log('Modal dismissed at: ' + new Date());
        });
    }
    
    
    
    
    
    
    
    

}]);



